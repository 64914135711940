import { render, staticRenderFns } from "./FastselectSetting.vue?vue&type=template&id=938b7c50&scoped=true"
import script from "./FastselectSetting.vue?vue&type=script&lang=js"
export * from "./FastselectSetting.vue?vue&type=script&lang=js"
import style0 from "./FastselectSetting.vue?vue&type=style&index=0&id=938b7c50&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "938b7c50",
  null
  
)

export default component.exports