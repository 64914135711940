var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff-customer"},[_c('div',{staticClass:"staff-customer-input"},[_c('el-input',{attrs:{"placeholder":"搜索","size":"small"},on:{"input":_vm.monitorSearchNull},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchEmployee.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.dataType === 'all' || _vm.dataType === 'staff')?_c('el-tab-pane',{attrs:{"label":"选择员工","name":"staff"}},[(_vm.corpList.length > 1)?_c('el-select',{staticStyle:{"width":"100%","margin-bottom":"10px"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.corpId),callback:function ($$v) {_vm.corpId=$$v},expression:"corpId"}},_vm._l((_vm.corpList),function(item){return _c('el-option',{key:item.originData.corpId,attrs:{"label":item.label,"value":item.originData.corpId}},[(item.originData && item.originData.wechatBotDTO && item.originData.wechatBotDTO.bindType == 0 && item.originData.wechatBotDTO.wechatType == 0)?_c('open-data',{attrs:{"type":'departmentName',"openid":item.label}}):_c('span',[_vm._v(_vm._s(item.label))])],1)}),1):_vm._e(),_vm._l((_vm.treeData),function(item,index){return _c('div',{key:index},[_c('el-tree',{key:"selectOrganizationTreeLazy",ref:item.id+'TreeLazy',refInFor:true,staticClass:"filter-tree",attrs:{"data":[item],"id":"selectOrganizationTreeLazy","node-key":"id","default-expanded-keys":_vm.defaultExpandedKeysMember,"expand-on-click-node":false,"props":{
                isLeaf: 'leaf',
              }},scopedSlots:_vm._u([{key:"default",fn:function({node,data}){return _c('span',{staticClass:"custom-tree-node",on:{"click":function($event){return _vm.onNodeClick(data,'',item,node)}}},[_c('span',{staticClass:"custom-tree-node-left",class:data.dataType == 'hasNext'?'load-more-node':''},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(data.type === 1 && !data.isLeaf),expression:"data.type === 1 && !data.isLeaf"}],class:['el-icon-caret-right',data.showChildren?'down':'right'],on:{"click":function($event){$event.stopPropagation();return _vm.nodeExpand(data,node,item)}}}),(
                    data.isWeWorkThirdData ||
                    item.isWeWorkThirdData ||
                    (item.originData&&
                    item.originData.wechatBotDTO&&
                    item.originData.wechatBotDTO.bindType===0&&
                    item.originData.wechatBotDTO.wechatType===0))?_c('span',{staticClass:"data-label"},[_c('open-data',{attrs:{"type":data.type == 1 ? 'departmentName' : 'userName',"openid":data.label}})],1):_c('span',{staticClass:"data-label"},[(_vm.chooseType === 'dept')?_c('span',[(data.type == 1 || data.dataType == 'hasNext')?_c('span',[_vm._v(_vm._s(data.label))]):_vm._e()]):_c('span',[_vm._v(_vm._s(data.label))])]),(data.originData && data.originData.departmentFullName && _vm.keyword && data.type == 0)?[_vm._v("   "+_vm._s("(")+" "),_vm._l((_vm.getterDepartments(data.originData.departmentFullName)),function(cell,index){return _c('div',{key:index},[_c('el-tooltip',{attrs:{"placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(data.isWeWorkThirdData)?[_c('open-data',{attrs:{"type":"departmentName","openid":cell}})]:_c('span',{domProps:{"innerHTML":_vm._s(cell)}})],2),(data.isWeWorkThirdData)?_c('div',[_vm._v(" "+_vm._s(index != 0 ? "/" : "")+" "),_c('open-data',{attrs:{"type":"departmentName","openid":cell}})],1):_c('span',[_vm._v(" "+_vm._s(index != 0 ? "/" : "")+" "+_vm._s(cell)+" ")])])],1)}),_vm._v(" "+_vm._s(")")+" ")]:_vm._e()],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(data.checked),expression:"data.checked"}]},[_c('i',{class:['el-icon-check']})])])}}],null,true)})],1)})],2):_vm._e(),(_vm.dataType === 'all' || _vm.dataType === 'updown')?_c('el-tab-pane',{attrs:{"label":"选择上下游","name":"upDown"}},[_c('el-tree',{ref:"upDownTree",staticClass:"updown-tree",attrs:{"props":{
          isLeaf: 'leaf',
          label: 'name',
          children:'children'
        },"data":_vm.updownDateTree,"load":_vm.loadNode,"lazy":"","expand-on-click-node":false,"node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function({node,data}){return _c('span',{class:['custom-tree-node',!data.wxOpenId && data.selfType===3 && !_vm.insertIm ? 'not-allowed' : 'allowed'],on:{"click":function($event){return _vm.onNodeClick(data,'updown',data,node)}}},[_c('span',{staticClass:"custom-tree-node-left"},[_c('span',{staticClass:"data-label"},[_vm._v(_vm._s(data.name))])]),(data.checked)?_c('span',[_c('i',{class:['el-icon-check']})]):_vm._e()])}}],null,false,1823201132)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }