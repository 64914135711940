<template>
    <div id="botSetting">
        <div :class="['content-set',currentTab === 'bot-setting-sessionKeep' ? 'no-margin' : '']">
            <div class="bottom-save">
                <span
                    v-if="
                        !isSave ||
                        this.feedbackIsChange ||
                        this.fastSelectIsChange
                    "
                    class="saveTip">{{$t('botIntent.botSettingNew.saveTip')}}</span >
                <el-button
                    v-if="RP_Visible('OPEN_CONFIG_BOT_SET')"
                    size="medium"
                    round
                    type="primary"
                    @click="updateBot()"
                    icon="guoran-tongyichicun-16-03-baocun iconfont"
                    >{{$t('common.save')}}</el-button>
                
            </div>
            <!--  不识别推荐意图-->
            <div class="settingItem  keep" v-if="currentTab === 'bot-setting-noDescernIntentON'">
                <div class="title-header">
                    <span class="title">{{$t('botIntent.botSettingNew.noDescernIntent')}}</span>
                    <div class="right">
                        <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                            <el-switch
                                @click.native.prevent="changeIntent('switch')"
                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                :width="42"
                                v-model="settingList.setting.noDescernIntentON"
                                active-value="1"
                                inactive-value="0"
                                active-color="#366AFF"
                                inactive-color="#E2E2E2">
                            >
                            </el-switch>
                            <span
                            class="switch-open-icon"
                            @click="changeIntent('open')"
                            v-if="settingList.setting.noDescernIntentON === '1'"
                            ><i class="iconfont guoran-a-16-17"></i
                            ></span>
                            <span
                            class="switch-close-icon"
                            @click="changeIntent('close')"
                            v-if="settingList.setting.noDescernIntentON === '0'"
                            ><i class="arsenal_icon arsenalcuo1"></i
                            ></span>
                        </div>
                        <i class="iconfont guoran-guanzhuren"></i>
                        <span class="des">{{$t('botIntent.botSettingNew.openTip')}}</span>
                    </div>
                </div>
                <div class="itemContent">
                    <div>
                        <span class="Label">{{$t('botIntent.botSettingNew.noDescernIntentLead')}}</span>
                        <el-input
                                :placeholder="$t('botIntent.botSettingNew.noDescernIntentLeadPla')"
                                v-model="settingList.setting.noDescernIntentLeadMessage"
                                class="rec"
                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                maxlength="100"
                                @focus="inputfucusRules"
                                @blur="inputBlurRules"
                        ></el-input>
                        <span
                                style="color:red;margin-left:10px"
                                v-if="noDescernIntentLeadMessageNull"
                        >{{$t('botIntent.botSettingNew.leadTip')}}</span>
                    </div>
                    <div class="divider-line"></div>
                    <div class="last-choose-option">
                        <div class="switch-content">
                            <span class="title">{{$t('botIntent.botSettingNew.lastOption')}}</span>
                               <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                                    <el-switch
                                        @click.native.prevent="changeNoLast('switch')"
                                        :width="42"
                                        v-model="settingList.setting.noDescernLastKeyWordRecommendIntents"
                                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                        active-color="#366AFF"
                                        inactive-color="#E2E2E2">
                                    >
                                    </el-switch>
                                    <span
                                    class="switch-open-icon"
                                    @click="changeNoLast('open')"
                                    v-if="settingList.setting.noDescernLastKeyWordRecommendIntents"
                                    ><i class="iconfont guoran-a-16-17"></i
                                    ></span>
                                    <span
                                    class="switch-close-icon"
                                    @click="changeNoLast('close')"
                                    v-if="!settingList.setting.noDescernLastKeyWordRecommendIntents"
                                    ><i class="arsenal_icon arsenalcuo1"></i
                                    ></span>
                                </div>
                            </div>
                        
                        <div class="last-config">
                            <div class="last-config-left">
                                <div class="labels">{{$t('botIntent.botSettingNew.lastOptionText')}}</div>
                                <el-input
                                    maxlength="30"
                                    :placeholder="$t('common.inputPlaceholder')"
                                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                    v-model="settingList.setting.noDescernLastKeyWordRecommends.keywords"
                                    class="last-text">
                                </el-input>
                            </div>
                            <div class="last-config-right">
                                <div class="labels">{{$t('botIntent.botSettingNew.executionIntention')}}</div>
                                <el-cascader
                                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                    ref="noDescern"
                                    :placeholder="$t('botIntent.searchOrSelectIntent')"
                                    clearable="cascader"
                                    :options="intentTreeOther"
                                    :props="lastProps"
                                    :show-all-levels="false"
                                    v-model="settingList.setting.noDescernLastKeyWordRecommends.intentIds"
                                    filterable>
                                </el-cascader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 会话保持 -->
            <div class="settingItem keep" v-if="currentTab === 'bot-setting-sessionKeep' && false">
                <div class="title-header">
                    <span class="title">{{$t('botIntent.sessionKeep')}}</span>
                     <div class="right">
                        <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                            <el-switch
                                @click.native.prevent="changesessionKeep('switch')"
                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                :width="42"
                                v-model="settingList.setting.sessionKeep"
                                active-color="#366AFF"
                                inactive-color="#E2E2E2">
                            >
                            </el-switch>
                            <span
                            class="switch-open-icon"
                            @click="changesessionKeep('open')"
                            v-if="settingList.setting.sessionKeep"
                            ><i class="iconfont guoran-a-16-17"></i
                            ></span>
                            <span
                            class="switch-close-icon"
                                @click="changesessionKeep('close')"
                            v-if="!settingList.setting.sessionKeep"
                            ><i class="arsenal_icon arsenalcuo1"></i
                            ></span>
                        </div>
                        <i class="iconfont guoran-guanzhuren"></i>
                        <span class="des">{{$t('botIntent.botSettingNew.sessionKeepOpenTip')}}</span>
                     </div>
                </div>
                <div class="itemContent one">
                    <el-input :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')" :placeholder="$t('botIntent.botSettingNew.timePlaceholder')"
                        v-model="settingList.setting.sessionKeepTime" min="0" type="number"
                        style="width: 100px;padding-right: 10px">
                    </el-input>
                    <span>{{$t('botIntent.botSettingNew.timeTip')}}</span>
                </div>
            </div>
            <!-- 关键词推荐 -->
            <div class="settingItem first" v-if="currentTab === 'bot-setting-keyWordRecommendIntents'">
                <div class="title-header">
                    <span class="title">{{$t('botIntent.botSettingNew.keyWordRecommendIntents')}}</span>
                    <div class="right">
                        <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                        <el-switch
                            @click.native.prevent="changekeyWordRecommendIntents('switch')"
                            :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                            :width="42"
                            v-model="settingList.setting.keyWordRecommendIntents"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2">
                        >
                        </el-switch>
                        <span
                        class="switch-open-icon"
                        @click="changekeyWordRecommendIntents('open')"
                        v-if="settingList.setting.keyWordRecommendIntents"
                        ><i class="iconfont guoran-a-16-17"></i
                        ></span>
                        <span
                        class="switch-close-icon"
                            @click="changekeyWordRecommendIntents('close')"
                        v-if="!settingList.setting.keyWordRecommendIntents"
                        ><i class="arsenal_icon arsenalcuo1"></i
                        ></span>
                        </div>
                        <i class="iconfont guoran-guanzhuren"></i>
                        <span class="des">{{$t('botIntent.botSettingNew.keyRecommendTip')}}</span>
                    </div>
                </div>
                <!-- 关键词-智能推荐 -->
                <div :class="['botSetting-keyword-recommend-box',isOpen ? 'open' : 'close']" v-if="keyWordRecommendAi&&keyWordRecommendAi.length>0">
                    <div class="keyword-recommend-box-header">
                        <div class="keyword-recommend-box-header-left">
                            <i class="iconfont guoran-tongyichicun-jiqirentouxiang"></i>
                            <span class="ai-recommend">{{$t('botIntent.botSettingNew.aiRecommend')}}</span>
                            <i class="iconfont guoran-guanzhuren"></i>
                            <span class="guoran-guanzhuren-tips">{{$t('botIntent.botSettingNew.aiRecommendTip')}}</span>
                        </div>
                        <div class="keyword-recommend-box-header-right" @click="openClose">
                            <i :class="[!isOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i>
                        </div>
                    </div>
                    <div class="keyword-recommend-box-container">
                        <div class="keyword-item" v-for="(item,index) in keyWordRecommendAi" :key="item.id">
                            <!-- <el-tooltip class="item" :content="item.keyword" placement="top-start" effect="light"> -->
                                <div :class="['keyword-item-text','one-column-ellipsis','keyword-item-text'+item.id]">{{item.keyword}}</div>
                            <!-- </el-tooltip> -->
                            <div :class="['keyword-item-operation',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']" v-if="RP_Visible('OPEN_CONFIG_BOT_SET')">
                                <div class="keyword-recommend-use-btn" @click="useKeyWord(item,index)"><i class="iconfont guoran-tongyichicun-16-13-xinjian"></i><span>{{$t('botIntent.botSettingNew.use')}}</span></div>
                                <div :class="['keyword-recommend-copy-btn','keyword-recommend-copy-btn'+item.id]" @click="copyKeyWord(item,index)" data-clipboard-action="copy"> <i class="iconfont guoran-tongyichicun-fuzhi"></i><span>{{$t('botIntent.botSettingNew.copy')}}</span></div>
                                <div class="keyword-recommend-ingore-btn" @click="ingoreKeyWord(item,index)"><i class="iconfont guoran-tongyichicun-hulve"></i><span>{{$t('botIntent.botSettingNew.ignore')}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="itemContent">
                    <div>
                        <span class="Label">{{$t('botIntent.botSettingNew.keyWordLead')}}</span>
                        <el-input
                                :placeholder="$t('botIntent.botSettingNew.keyWordLeadPlaceholder')"
                                v-model="settingList.setting.keyWordRecommendIntentsGuidDesc"
                                class="rec"
                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                @focus="inputKeywordfucusRules"
                                @blur="inputKeywordBlurRules"
                                maxlength="100"
                        ></el-input>
                        <span
                                style="color:red;margin-left:10px"
                                v-if="keyWordRecommendIntentsGuidDescNull"
                        >{{$t('botIntent.botSettingNew.leadTip')}}</span>
                    </div>
                    <div class="divider-line"></div>
                    <ul class="keywords">
                        <li class="keywordItem title">
                            <span class="keyword">{{$t('botIntent.botSettingNew.keyWord')}}<span class="desc">{{$t('botIntent.botSettingNew.keyWordTip')}}</span>
                            </span>
                            <span class="intent">{{$t('botIntent.botSettingNew.associationIntention')}}</span>
                            <!-- <span v-if="RP_Visible('OPEN_CONFIG_BOT_SET')" class="operition">操作</span> -->
                        </li>
                        <li class="keywordItem" v-for="(item,index) in keyWordRecommends" :key="index">
                            <div class="last-config super">
                                <div class="last-config-left">
                                    <el-input
                                        :ref="'keyWordRecommends'+index"
                                        :placeholder="$t('common.inputPlaceholder')"
                                        v-model.trim="item.keywordsStr"
                                        class="last-text"
                                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                        @click.stop.native="keywordChangeFocus($event,item,index,'focus')"
                                        @input="keywordChange($event,item,index)">
                                    </el-input>
                                    <botKeywordRecommend 
                                        :key="'keywordRecommend' + index"
                                        :showBotKeywordRecommend="item.showBotKeywordRecommend"
                                        :dataObj="item"
                                        :keyword="item.keywordsStr"
                                        :dataIndex="index"
                                        :width="clientWidth" 
                                        :left="0"
                                        @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                        @addToInput="addToInput($event,item,index)">
                                    </botKeywordRecommend>
                                </div>
                                <div class="last-config-right">
                                    <!-- <el-cascader
                                        collapse-tags
                                        :ref="'changekeyWordRecommend'+index"
                                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                        :options="intentTree"
                                        :props="props"
                                        :show-all-levels="false"
                                        placeholder="搜索或选择意图"
                                        v-model="item.selectedOptions"
                                        @change="changeIntentGuan($event,item,index)"
                                        @remove-tag="removeTagkeyWordRecommend($event,item,index)"
                                        filterable>
                                    </el-cascader> -->
                                    <div class="input-div" @click="openChooseIntent(item,index)">
                                        <div class="have-checked-intent" v-if="item.intentChildDtos && item.intentChildDtos.length > 0">
                                            <div class="intent-name">{{item.intentChildDtos[0].name}}<i @click.stop="removeTagkeyWordRecommend(item,index)" class="el-icon-close"></i> </div>
                                            <div class="intent-num" v-if="item.intentChildDtos.length > 1"> + {{item.intentChildDtos.length-1}}</div>
                                        </div>
                                        <span class="placeholder-text" v-else>{{$t('botIntent.searchOrSelectIntent')}}</span>
                                    </div>
                                </div>
                                <span class="operition">
                                    <em
                                        :class="['el-icon-delete cursor',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']"
                                        :title="$t('botIntent.botSettingNew.deleteKeyWord')"
                                        @click="confirmDeleteKeyWord(item,index)"
                                    ></em>
                                </span>
                            </div>
                        </li>
                        <li class="keywordItem btn">
                            <el-button
                                @click="addKeyword"
                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                icon="el-icon-circle-plus-outline"
                                size="medium"
                                round
                            >{{$t('botIntent.botSettingNew.addKeyword')}}
                            </el-button>
                        </li>
                    </ul>
                    <div class="divider-line"></div>
                    <div class="last-choose-option">
                        <div class="switch-content">
                            <span class="title">{{$t('botIntent.botSettingNew.lastOption')}}</span>
                            <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                                <el-switch
                                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                    @click.native.prevent="changeKeyWordLast('switch')"
                                    :width="42"
                                    v-model="settingList.setting.lastKeyWordRecommendIntents"
                                    active-color="#366AFF"
                                    inactive-color="#E2E2E2">
                                >
                                </el-switch>
                                <span
                                class="switch-open-icon"
                                @click="changeKeyWordLast('open')"
                                v-if="settingList.setting.lastKeyWordRecommendIntents"
                                ><i class="iconfont guoran-a-16-17"></i
                                ></span>
                                <span
                                class="switch-close-icon"
                                @click="changeKeyWordLast('close')"
                                v-if="!settingList.setting.lastKeyWordRecommendIntents"
                                ><i class="arsenal_icon arsenalcuo1"></i
                                ></span>
                            </div>
                        </div>
                        
                        <div class="last-config">
                            <div class="last-config-left">
                                <div class="labels">{{$t('botIntent.botSettingNew.lastOptionText')}}</div>
                                <el-input
                                    maxlength="30"
                                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                    :placeholder="$t('botIntent.inputPlaceholder')"
                                    v-model="settingList.setting.lastKeyWordRecommends.keywords"
                                    class="last-text">
                                </el-input>
                            </div>
                            <div class="last-config-right">
                                <div class="labels">{{$t('botIntent.botSettingNew.executionIntention')}}</div>
                                <el-cascader
                                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                    clearable="cascader"
                                    :options="intentTreeOther"
                                    :props="lastProps"
                                    :placeholder="$t('botIntent.searchOrSelectIntent')"
                                    :show-all-levels="false"
                                    v-model="settingList.setting.lastKeyWordRecommends.intentIds"
                                    filterable>
                                </el-cascader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 答案反馈 -->
            <div class="settingItem" v-if="currentTab === 'bot-setting-feedback'">
                <FeedbackSeeting 
                    :feedbackSeetingObj="feedbackSeetingObj"
                    @changeSaveStatus="changeSaveStatus">
                </FeedbackSeeting>
            </div>
            <!-- 快捷选择 -->
            <div class="settingItem" v-if="currentTab === 'bot-setting-fastSelect'">
                <FastSelect 
                    key="fastSelectObj"
                    :fastSelectObj="fastSelectObj"
                    @changeFastSelectSaveStatus="changeFastSelectSaveStatus">
                </FastSelect>
            </div>
           
        </div>
        <div class="right-phone" v-if="currentTab !== 'bot-setting-sessionKeep' && (!isShowTest && !isShowHeathly)">
            <phoneConfig 
                :currentTab="currentTab"
                :phoneTitle="phoneTitle"
                :isShowQuick="isShowQuick"
                :isShowRecommnd="isShowRecommnd"
                :recommndTitle="recommndTitle"
                :isShowAanswer="isShowAanswer"
                :settingList="settingList"
                :setType="currentTab" 
                :qaList="qaList"
                :quickList="quickList">
            </phoneConfig>
        </div>
        <errorDialog
            class="import-error-dialog"
            @closeEvent="showErrorDialog = false"
            v-if="showErrorDialog"
            >
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('botIntent.botSettingNew.ignoreKeyword')}}</div>
                <div class="prompt-text">{{$t('botIntent.botSettingNew.ignoreKeywordTip')}}</div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="showErrorDialog = false"  plain round>{{$t('common.cancel')}}</el-button>
                <el-button class="down-load" @click="confirmBtn" round>{{$t('common.confirm')}}</el-button>
            </div>
        </errorDialog>
        <chooseKeywordIntent 
            :openChooseIntentDialog="openChooseIntentDialog" 
            :keywordList="keywordList"
            :intentTree="intentTree"
            :checkedIntentList="checkedIntentList"
            :keywordsStr="keywordsStr"
            @checkedIntent="checkedIntent">
        </chooseKeywordIntent>
    </div>
</template>
<script>
    import FeedbackSeeting from "./component/FeedbackSetting.vue"
    import FastSelect from "./component/FastselectSetting.vue"
    import Action_fast_selectVue from '../ChatFlow/component/action/Action_fast_select.vue';
    import phoneConfig from './component/phoneConfig.vue';
    import switchIcon from "../../components/switch-icon.vue";
    import  botKeywordRecommend from '../intentQa/components/botKeywordRecommend.vue'
    import errorDialog from '../../components/errorDialog.vue'
    import chooseKeywordIntent from './component/chooseKeywordIntent.vue'
    import {v4 as uuidv4} from "uuid";
    import Clipboard from "clipboard";
    export default {
        components: {
            FeedbackSeeting,
            FastSelect,
            phoneConfig,
            switchIcon,
            botKeywordRecommend,
            errorDialog,
            chooseKeywordIntent
        },
        props:{
            botSettingTab:{
                type:Number,
                default:1
            },
            currentTab:{
                type:String,
                default:''
            }
        },
        data() {
            return {
                isSave: true,
                isSwitch: true,
                isShowRecommnd:false, // 手机端是否展示推荐
                recommndTitle:'', // 推荐 标题
                isShowAanswer:false,
                isShowQuick:false,
                quickList:[],
                settingList: {
                    id: "",
                    //name: "第1个机器",
                    setting: {
                        nickName: "",
                        reply4NoCustomServer: "",
                        noDescernIntentON: "0",
                        noDescernIntentLeadMessage: "",
                        keyWordRecommendIntents: null,
                        keyWordRecommendIntentsGuidDesc: "",
                        sessionKeep: true,
                        sessionKeepTime: 30,
                        noDescernLastKeyWordRecommendIntents:false,
						noDescernLastKeyWordRecommends:{
                            keywords:'以上都不是',
                            intentIds:''
                        },
                        lastKeyWordRecommendIntents:false,
						lastKeyWordRecommends:{
                            keywords:'以上都不是',
                            intentIds:''
                        }
                    }
                },
                activeName: "second",
                tabPosition: "top",
                settingContentLi: true,
                settingContentSeniorLi: false,
                activeLiClass: "activeClass",
                activeSeniorLiClass: "",
                keyWordRecommends: [
                    {
                        keywords: [],
                        keywordsStr: "",
                        selectedOptions: [],
                        intentIds: [],
                        intents: [],
                        isUse:false,
                        intentChildDtos:[]
                    }
                ],
                dialogFormVisible: false,
                // keywordform: {
                //     keywords: '',
                //     intents:[],
                //     selectedOptions:[]
                // },
                // 添加还是编辑关键词规则
                isAdd: false,
                currentEditIndex: null,
                formLabelWidth: "100px",
                props: {multiple: true, value: "id", label: "name",emitPath:false},
                lastProps:{multiple: false, value: "id", label: "name",emitPath:false},
                options: [
                    {
                        value: null,
                        label: "",
                        children: [
                            {
                                value: null,
                                label: "",
                                children: [{value: null, label: ""}]
                            }
                        ]
                    }
                ],
                intentTree: [],
                loading: true,
                selectedOptions: [],
                keyWordRecommendsLength: "",
                serachIntentBox: false,
                noDescernIntentLeadMessageNull: false,
                keyWordRecommendIntentsGuidDescNull: false,
                feedbackSeetingObjStr: "{}",
                feedbackSeetingObj: {},
                feedbackIsChange: false,
                fastSelectObj: {
                    
                },
                fastSelectObjStr: "{}",
                fastSelectIsChange: false,
				qaList:['电脑/笔记本电源不稳定','电脑/笔记本电源故障'],
                phoneConfigTitle:'猜您可能想问',
                fastSelectObjCopy:{},
                phoneTitle:'',
                keyWordRecommendList:[],
                isShowTest:false,
                intentTreeOther:[],
                clientWidth:null,
                isFirst:true,
                isOpen:true,
                keyWordRecommendAi:[],
                clipboardExample:null,
                showErrorDialog:false,
                ingoreKeyWordItem:{},
                ingoreKeyWordIndex:-1,
                openChooseIntentDialog:false,
                keywordList:[],
                checkedIntentList:[],
                activeKeywordIndex:0,
                oldKeyWordRecommendAi:[],
                keywordsStr:"",
                useKeyWordList:[],
                copyKeyWordObj:{},
                copyKeyWordIndex:-1,
                useKeyWordObj:{},
                useKeyWordIndex:-1,
                keyWordRecommendsOld:[],
                oldKeyWordRecommendAiOld:[],
                isShowHeathly:false
            };
        },
        watch: {
            settingList: {
                handler(n, o) {
                    if(!this.isFirst){
                        this.isSave = false;
                    } else {
                        this.isSave = true;
                    }
                    this.isFirst = false;
                    if(this.currentTab === 'bot-setting-noDescernIntentON' || this.currentTab === 'bot-setting-sessionKeep'){
                        this.phoneConfigBase()
                    } else {
                        this.phoneConfigSenior();
                    }
                   
                },
                deep: true
            },
            feedbackSeetingObj:{
                handler(n){
                    this.phoneConfigSenior();
                },
                deep:true
            },
            fastSelectObjCopy:{
                 handler(n){
                    this.phoneConfigSenior();
                },
                deep:true
            },

            fastSelectObj:{
                handler(n){
                    this.phoneConfigSenior();
                },
                deep:true
            },
            keyWordRecommends: {
                handler(newValue, oldValue) {
                    // console.log(JSON.parse(JSON.stringify(newValue)),'newValuenewValuenewValuenewValue');
                    if (
                        JSON.stringify(oldValue) ==
                        '[{"keywords":[],"keywordsStr":"","selectedOptions":[],"intentIds":[],"intents":[],intentChildDtos:[]}]' &&
                        JSON.stringify(newValue) == "[]" &&
                        this.isSave
                    ) {
                    
                        this.isSave = true;
                    } else {
                        if (!this.isSwitch) {
                            console.log(11111111111111);
                            this.isSave = false;
                        } else {
                            this.isSwitch = false;
                        }
                    }
                    if(this.currentTab === 'bot-setting-keyWordRecommendIntents'){
                        this.$nextTick(() => {
                            let nodeList = [];
                            if(newValue.length > 0){
                                this.keyWordRecommendList = [];
                                this.phoneTitle  = newValue[0].keywords && newValue[0].keywords[0] ? newValue[0].keywords[0] : '';
                                    newValue.forEach((item,index) => {
                                        if(index === 0){
                                            let res = 'changekeyWordRecommend' + index;
                                            if(item.selectedOptions.length > 0){
                                                // nodeList = this.$refs[res][0].getCheckedNodes(true);
                                                // nodeList.forEach((v,i) => {
                                                //     this.keyWordRecommendList.push(v.data); 
                                                //     this.phoneConfigSenior()
                                                // })
                                                this.keyWordRecommendList = item.intentChildDtos; 
                                                this.phoneConfigSenior()
                                            }
                                        }
                                    })
                            } else {
                                this.phoneTitle = '';
                                this.keyWordRecommendList = [];
                                this.phoneConfigSenior()
                            }
                            
                        })
                    }
                },
                deep: true,
            },
            currentTab:{
               handler(n){
                    this.useKeyWordList = [];
                    this.getBotInfo();
                    this.getBotadvancedSetting();
                    // if(n !== 'bot-setting-noDescernIntentON' && n !== 'bot-setting-sessionKeep'){
                        this.isSave = true;
                        this.feedbackIsChange = false;
                        this.fastSelectIsChange = false;
                        this.phoneConfigSenior();
                    if(this.currentTab === 'bot-setting-noDescernIntentON' || this.currentTab === 'bot-setting-sessionKeep'){
                        this.phoneConfigBase();
                    } else {
                        this.phoneConfigSenior();
                    }
               },
               immediate:true
            },
            useKeyWordList:{
                handler(n){
                    // console.log(n,'useKeyWordListuseKeyWordListuseKeyWordList');
                },
                deep:true,
                immediate:true
            },
            botSettingTab(n){
                console.log(n,'nnnnnnnnnnnnn');
                if(n == 1){

                } else {
                   if(n === 'KEYWORD_REC'){
                        // 机器人设置-高级设置-关键词推荐
                        this.currentTab = 'bot-setting-keyWordRecommendIntents'
                    } else if(n === 'ANSWER_FEEDBACK'){
                        // 机器人设置-高级设置-答案反馈"
                       this.currentTab = 'bot-setting-feedback'
                    } else if(n === 'FAST_SELECT'){
                        // 机器人设置-高级设置-快捷选择"
                        this.currentTab = 'bot-setting-fastSelect'
                    } else if(n === 'bot-setting-noDescernIntentON'){
                        // 机器人设置-高级设置-快捷选择"
                        this.currentTab = 'bot-setting-fastSelect'
                    }
                }
            },
            isSave(n){
                console.log(n,'mmmmm');
            }
        },
		created(){
			this.resetSuperData();
		},
		mounted() {
            this.isFirst = true;
            this.isSwitch = true;
            this.isSave = true;
            this.feedbackIsChange = false;
            this.fastSelectIsChange = false;
            this.$eventBus.$on("test-window-visible", (visible) => {
                this.isShowTest = visible;
                if(this.isShowTest){
                    this.isShowHeathly = false;
                }
            });
            this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
            this.$eventBus.$on("bot-heathly-visible", (visible) => {
                this.isShowHeathly = visible.flag;
                if(this.isShowHeathly){
                    this.isShowTest = false;
                }
            });
            this.isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
			this.getIntentList();
            this.getIntentListOther();
            this.$nextTick(() => {
                document.querySelector('#main').addEventListener('click',() => {
                    if(this.isIntentFocus){
                        this.keyWordRecommends.forEach((item,index) => {
                            this.$set(item,'showBotKeywordRecommend',false)
                            item.showBotKeywordRecommend = false;
                        })
                        this.isIntentFocus = false;
                        this.$forceUpdate();
                    }
                })
            })
            this.getKeywordRecommend();
            this.cancelRedPot();
        },
        methods: {
            addKeyword(){
                this.keyWordRecommends.push({
                    keywordsStr:'',
                    selectedOptions:[],
                    intents:[],
                    intentIds:[],
                    showBotKeywordRecommend:false,
                    isUse:false,
                    intentChildDtos:[]
                })
                this.keyWordRecommendsOld = JSON.parse(JSON.stringify(this.keyWordRecommends))
            },
            checkedIntent(type,list){
                if(type == 'save'){
                     console.log(list,'listlist');
                    let intentIds = [],selectedOptions = [];
                    if(list && list.length > 0){
                        list.forEach((item,index) => {
                            intentIds.push(item.id);
                            selectedOptions.push(item.id);
                        })
                    }
                    this.keyWordRecommends[this.activeKeywordIndex].selectedOptions = selectedOptions;
                    this.keyWordRecommends[this.activeKeywordIndex].intentIds = intentIds;
                    this.keyWordRecommends[this.activeKeywordIndex].intentChildDtos = list;
                }
                this.openChooseIntentDialog = false;

               
                console.log( this.keyWordRecommendList,1111111);
            },
            // 打开选择意图弹框
            openChooseIntent(item,index){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                console.log(item,index);
                this.keywordList = item.keywords || [];
                //  this.keywordList = [...item.keywords,...item.keywords,...item.keywords]
                this.activeKeywordIndex = index;
                this.checkedIntentList = item.intentChildDtos;
                this.keywordsStr = item.keywordsStr;
                this.openChooseIntentDialog = true;
            },
            cancelRedPot(){
                this.FetchPut(this.requestUrl.bot.cancelRedPot,this.$route.query.id).then(res => {
                    if(res.code === '0'){
                        sessionStorage.setItem('keywordRecommendHasNew',false);
                        this.$emit('changeKeywordRecommendHasNew',false)
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            // 获取推荐关键词
            getKeywordRecommend(){
                this.FetchGet(this.requestUrl.bot.getKeywordRecommend + "/" + this.$route.query.id).then(res => {
                    if (res.code === "0") {
                        this.keyWordRecommendAi = res.data || [];
                        this.oldKeyWordRecommendAiOld = JSON.parse(JSON.stringify(this.keyWordRecommendAi));
                        // this.keyWordRecommendAi = [...[{
                        //     keyword:'奶粉节',
                        //     id:1
                        // }],...this.keyWordRecommendAi]
                       
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            // 展开收起
            openClose(){
                this.isOpen = !this.isOpen;
            },
            // 使用
            useKeyWord(item,index){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                console.log('useKeyWord');
                // this.keyWordRecommendAi.splice(index,1);
                this.clientWidth = document.querySelector('.last-config-left .el-input').clientWidth;
                this.keyWordRecommends.forEach((v,i) => {
                    v.showBotKeywordRecommend = false;
                })
                
                this.keyWordRecommends.push({
                    // isDel:false,
                    // firstKeywordsStr:item.keyword,
                    isUse:true,
                    keywordsStr:item.keyword,
                    selectedOptions:[],
                    showBotKeywordRecommend:false,
                    keywords: item.keyword !== '' ? item.keyword.replace(/，/g, ",").split(",") : [],
                    intentChildDtos:[]
                })
               
                // this.useKeyWordObj = JSON.parse(JSON.stringify(item));
                // this.useKeyWordIndex = index;
                // this.useKeyWordList.push(item);
                let ind = this.keyWordRecommends.length-1;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.isIntentFocus = true;
                        this.$refs['keyWordRecommends'+ind][0].focus();
                        this.keyWordRecommends[ind].showBotKeywordRecommend = true;
                    },100)
                })
                this.keyWordRecommendsOld = JSON.parse(JSON.stringify(this.keyWordRecommends))
                this.handlerUserAddCopy('',this.keyWordRecommends[this.keyWordRecommends.length-1],this.keyWordRecommends.length-1);
            },
            // 复制
            copyKeyWord(item,index){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                let clipboard = new Clipboard('.keyword-recommend-copy-btn'+item.id, {
                    text: function () {
                        return item.keyword
                    }
                })
                clipboard.on('success', e => {
                    this.$message.success(this.$t('botIntent.botSettingNew.copySuccess'));
                    this.copyKeyWordObj = JSON.parse(JSON.stringify(item));
                    this.copyKeyWordIndex = index;
                    // this.keyWordRecommends.push({
                    //     keywordsStr:'',
                    //     selectedOptions:[],
                    //     showBotKeywordRecommend:false,
                    //     keywords:[]
                    // })
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    this.$message.warning('复制失败');
                    clipboard.destroy()
                })
            },
            // 忽略
            ingoreKeyWord(item,index){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                // 忽略后，将不再推荐该关键词，是否忽略？
                this.showErrorDialog = true;
                this.ingoreKeyWordItem = item;
                this.ingoreKeyWordIndex = index;
            },
            confirmBtn(){
                this.FetchPut(this.requestUrl.bot.ignoreConfigKeyword,this.ingoreKeyWordItem.id).then(res => {
                    if (res.code === "0") {
                        this.showErrorDialog = false;
                        this.keyWordRecommendAi.splice(this.ingoreKeyWordIndex,1);
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            closeBotKeywordRecommend(item){
                item.showBotKeywordRecommend = false;
                this.$forceUpdate()
            },
             //添加实体 关键词的近义词 , 单个添加
            addToInput(datas,item,index){   
                item.keywordsStr = datas;
                // item.showBotKeywordRecommend = false;
                this.$forceUpdate()
            },
            // 添加实体 显隐推荐词下拉框
            changeInput(datas,item,index){
                console.log(datas,item,index);
                this.clientWidth = document.querySelector('.entity-row .el-textarea').clientWidth-35;
                console.log(this.clientWidth);
                item.showBotKeywordRecommend = true;
                this.$forceUpdate();
            },
            changeIntentGuan(datas,item,index){
                item.intentIds = [...item.selectedOptions];
            },
             // // 关键词推荐 : 添加关键词和关联意图 : 移除tag
            removeTagkeyWordRecommend(item,index){
                // this.keyWordRecommendList.forEach((v,i) => {
                //     if(v.id == datas && index === 0){
                //         this.keyWordRecommendList.splice(i,1)
                //     }
                // })
                // item.selectedOptions.shift();
                // item.intentIds.shift();
                item.intentChildDtos.shift();
                // this.keyWordRecommendList.shift();
                this.phoneConfigSenior();
            },
            // 不识别推荐的最后一项开关状态改变
            changeNoLast(datas){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                if(datas === 'open'){
                    this.settingList.setting.noDescernLastKeyWordRecommendIntents = false;
                } else if (datas === 'close'){
                    this.settingList.setting.noDescernLastKeyWordRecommendIntents = true;
                } 
            },
            // 关键词推荐的最后一项开关状态改变
            changeKeyWordLast(datas){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                if(datas === 'open'){
                    this.settingList.setting.lastKeyWordRecommendIntents = false;
                } else if (datas === 'close'){
                    this.settingList.setting.lastKeyWordRecommendIntents = true;
                } 
            },
            changeIntent(datas){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                if(datas === 'open'){
                    this.settingList.setting.noDescernIntentON = '0';
                } else if (datas === 'close'){
                    this.settingList.setting.noDescernIntentON = '1';
                }
            },
            changesessionKeep(datas){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                if(datas === 'open'){
                    this.settingList.setting.sessionKeep = false;
                } else if (datas === 'close'){
                    this.settingList.setting.sessionKeep = true;
                }
            },
            changekeyWordRecommendIntents(datas){
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
                if(datas === 'open'){
                    this.settingList.setting.keyWordRecommendIntents = false;
                    this.qaList.pop();
                } else if (datas === 'close'){
                    this.settingList.setting.keyWordRecommendIntents = true;
                }
            },
            // 切换基本/高级设置时 , 右侧手机示例中的数据变化
            phoneConfigBase(){
                this.qaList = [];
                if(this.currentTab === 'bot-setting-noDescernIntentON'){
                    this.phoneTitle = '电脑耗电快'
                    this.isShowRecommnd = this.settingList.setting.noDescernIntentON == 1 ? true : false;
                    this.recommndTitle = this.settingList.setting.noDescernIntentLeadMessage;
                    if(this.settingList.setting.noDescernLastKeyWordRecommendIntents){
                        this.qaList = [...['电脑/笔记本电源不稳定','电脑/笔记本电源故障'],...[this.settingList.setting.noDescernLastKeyWordRecommends.keywords === '' ? '以上都不是' : this.settingList.setting.noDescernLastKeyWordRecommends.keywords]]
                    } else {
                        this.qaList = ['电脑/笔记本电源不稳定','电脑/笔记本电源故障']
                    }
                } else {
                    // this.qaList = ['电脑/笔记本电源不稳定','电脑/笔记本电源故障','以上都不是']
                    // this.isShowRecommnd = false;
                    // this.recommndTitle = '猜您可能想问'
                }
            },
            phoneConfigSenior(){
                // 关键词推荐
                if(this.currentTab === 'bot-setting-keyWordRecommendIntents'){
                    this.qaList = [];
                    this.isShowRecommnd = this.settingList.setting.keyWordRecommendIntents;
                    this.recommndTitle = this.settingList.setting.keyWordRecommendIntentsGuidDesc;
                    this.keyWordRecommendList.forEach((item,index) => {
                        if(item.exampleQuestion && item.exampleQuestion !== ''){
                            this.qaList.push(item.exampleQuestion)
                        }
                    })
                    if(this.settingList.setting.lastKeyWordRecommendIntents){
                        this.qaList = [...this.qaList,...[this.settingList.setting.lastKeyWordRecommends.keywords === '' ? '以上都不是' : this.settingList.setting.lastKeyWordRecommends.keywords]];
                    } else {
                        // this.qaList.pop();
                    }
                } else {
                //     this.qaList = ['电脑/笔记本电源不稳定','电脑/笔记本电源故障','以上都不是']
                //     this.recommndTitle = '猜您可能想问'
                    // 答案反馈
                    if(this.currentTab === 'bot-setting-feedback'){
                        this.isShowRecommnd = this.feedbackSeetingObj.feedback;
                        this.phoneTitle = '公司WIFI密码是多少？';
                        if(this.isE()) {
                            this.qaList = ['WIFI名称：eBot','WIFI密码：eBot2019120','访客专用WIFI名称：eBotGust','访客专用WIFI密码：eBotGust']
                        } else {
                            this.qaList = ['WIFI名称：AskBot','WIFI密码：AskBot2019120','访客专用WIFI名称：AskBotGust','访客专用WIFI密码：AskBotGust']
                        }
                        this.isShowAanswer = this.feedbackSeetingObj.feedback;
                    } else {
                        this.isShowAanswer = false;
                    }
                    // 快捷选择
                    if(this.currentTab === 'bot-setting-fastSelect'){
                         this.isShowRecommnd = false;
                        this.isShowQuick = this.fastSelectObjCopy.fastSelect;
                        this.quickList = [];
                        this.fastSelectObjCopy.fastSelectSettingGroups && this.fastSelectObjCopy.fastSelectSettingGroups.forEach((item,index) => {
                           if(index === 0){
                                item.list && item.list.forEach(v => {
                                    this.quickList.push(v.text)
                                })
                           }
                        })
                    } else {
                        this.isShowQuick = false;
                    }
                }
            },
			resetSuperData(){
				this.feedbackSeetingObj = {
					feedback: false,
					positiveFeedback: {
						scope: "TEXT",
						text: this.$t('botIntent.used'),
						value: ""
					},
					negativeFeedback: {
						scope: "TEXT",
						text: this.$t('botIntent.noUse'),
						value: ""
					}
				}
			},
			// 不识别推荐意图开关
			switchChange(){
				this.settingList.setting.noDescernIntentON = this.settingList.setting.noDescernIntentON == 1 ? false : true;
			},
			// 关键词推荐开关改变
			switchChangeKeyword(){
				this.settingList.setting.keyWordRecommendIntents = !this.settingList.setting.keyWordRecommendIntents;
			},
			// 
			sessionKeepChange(){
				this.settingList.setting.sessionKeep = !this.settingList.setting.sessionKeep;
			},
            changeSaveStatus() {
                if (this.feedbackSeetingObjStr != JSON.stringify(this.feedbackSeetingObj)) {
                    this.feedbackIsChange = true;
                } else {
                    this.feedbackIsChange = false;
                }
            },
            changeFastSelectSaveStatus(datas) {
                this.fastSelectObjCopy = datas;
                if (this.fastSelectObjStr != JSON.stringify(datas)) {
                    this.fastSelectIsChange = true;
                } else {
                    this.fastSelectIsChange = false;
                }
            },
            //推荐意图输入框失去焦点规则
            inputBlurRules() {
                let noDescernIntentON = this.settingList.setting.noDescernIntentON;
                let noDescernIntentLeadMessage = this.settingList.setting
                    .noDescernIntentLeadMessage;
                if (
                    (noDescernIntentON == 1 && noDescernIntentLeadMessage == null) ||
                    (noDescernIntentON == 1 && noDescernIntentLeadMessage == "")
                ) {
                    this.noDescernIntentLeadMessageNull = true;
                }
            },
            inputfucusRules() {
                this.noDescernIntentLeadMessageNull = false;
            },
            //keyword输入框失去焦点规则
            inputKeywordBlurRules() {
                let keyWordRecommendIntents = this.settingList.setting
                    .keyWordRecommendIntents;
                let keyWordRecommendIntentsGuidDesc = this.settingList.setting
                    .keyWordRecommendIntentsGuidDesc;
                if (
                    (keyWordRecommendIntents == true &&
                        keyWordRecommendIntentsGuidDesc == null) ||
                    (keyWordRecommendIntents == true &&
                        keyWordRecommendIntentsGuidDesc == "")
                ) {
                    this.keyWordRecommendIntentsGuidDescNull = true;
                }
            },
            inputKeywordfucusRules() {
                this.keyWordRecommendIntentsGuidDescNull = false;
            },
            confirmDeleteKeyWord(item,index) {
                if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return
                console.log(item,'itemitem');
                this.$confirm(this.$t('botIntent.botSettingNew.confirmDeleteKeyWordTip'), this.$t('botIntent.botSettingNew.confirmDeleteKeyWordTitle'), {
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    type: "warning"
                }).then(() => {
                    console.log(this.oldKeyWordRecommendAi);
                   this.handlerUserAddCopy('del',item,index)
                    // this.keyWordRecommends.splice(index, 1);
                })
                .catch(() => {
                });
            },
            keywordChangeFocus(datas,item,index) {
                console.log(item,'itemitemitem');
                this.isIntentFocus = true;
                this.clientWidth = document.querySelector('.last-config-left .el-input').clientWidth;
                this.keyWordRecommends.forEach((v,i) => {
                    v.showBotKeywordRecommend = false;
                })
                if(item.keywordsStr !== ''){
                    item.showBotKeywordRecommend = true;
                    this.$forceUpdate();
                } 
                item.keywords = item.keywordsStr !== '' ? item.keywordsStr.replace(/，/g, ",").split(",") : [];
                this.keyWordRecommendsOld = JSON.parse(JSON.stringify(this.keyWordRecommends))
                this.$forceUpdate();
            },
            keywordChange(datas,item,index) {
                this.isIntentFocus = true;
                this.clientWidth = document.querySelector('.last-config-left .el-input').clientWidth;
                this.keyWordRecommends.forEach((v,i) => {
                    v.showBotKeywordRecommend = false;
                })
                if(item.keywordsStr !== ''){
                    item.showBotKeywordRecommend = true;
                    this.$forceUpdate();
                } 
                item.keywords = item.keywordsStr !== '' ? item.keywordsStr.replace(/，/g, ",").split(",") : [];
                this.keyWordRecommendsOld = JSON.parse(JSON.stringify(this.keyWordRecommends))
                this.handlerUserAddCopy('',item,index)

                this.$forceUpdate();
            },
            handlerUserAddCopy(type,item,index){

                console.log(type,item,index,'item111');
                console.log(this.keyWordRecommends,'总的');                
                let list = [];
                this.keyWordRecommends.forEach((keyWordRecommendsItem,keyWordRecommendsIndex) => {
                    list.push(...keyWordRecommendsItem.keywords || []);
                })
                console.log(list,'list');
                let useList = [];
                if(type === 'del'){
                    console.log(this.useKeyWordList,1111111111);
                    this.useKeyWordList =  this.useKeyWordList.filter(useKeyWordItem => {
                        if(item.keywords.indexOf(useKeyWordItem.keyword) === -1){
                            return item
                        } else {
                            this.keyWordRecommendAi.push(useKeyWordItem);
                        }
                    });
                    this.keyWordRecommends.splice(index,1);
                } else {
                    
                    // 使用的
                    this.keyWordRecommendAi.forEach((RecommendAiItem,RecommendAiIndex) => {
                        if(item.keywords.indexOf(RecommendAiItem.keyword) !== -1){
                            useList.push(RecommendAiItem);
                            this.keyWordRecommendAi.splice(RecommendAiIndex,1)
                        }
                    })
                    this.useKeyWordList = this.handlerArray([...this.useKeyWordList,...useList])

                    this.useKeyWordList.forEach((useKeyWordItem,useKeyWordIndex) => {
                        if(list.indexOf(useKeyWordItem.keyword) === -1){
                            this.oldKeyWordRecommendAiOld.forEach((v,i) => {
                                if(useKeyWordItem.keyword === v.keyword){
                                    this.keyWordRecommendAi.push(v);
                                    this.useKeyWordList.splice(useKeyWordIndex,1)
                                }
                            })
                        }
                    })
                }
                this.keyWordRecommendAi = this.handlerArray(this.keyWordRecommendAi)
                console.log(this.useKeyWordList,'useKeyWordList//////////');
            },
            // 数组对象去重
            handlerArray(arr) {
                let map = new Map();
                for (let item of arr) {
                    map.set(item.id, item);
                }
                arr = [...map.values()];
                return arr
            },
            editAdvanceSetting(index, item, isAdd) {
                this.isAdd = true;
                if (isAdd) {
                    this.keywordform.keywords = "";
                    this.keywordform.intents = [];
                    this.keywordform.selectedOptions = [];
                } else {
                    this.currentEditIndex = index;
                    this.dialogFormVisible = true;
                    this.keywordform.keywords = item.keywords.join(",");
                    this.keywordform.intents = item.intents;
                    this.keywordform.selectedOptions = [];
                    for (let i = 0, len = item.intents.length; i < len; i++) {
                        let intent = item.intents[i];
                        let option = ["S" + intent.skillId, "T" + intent.typeId, intent.id];
                        this.keywordform.selectedOptions.push(option);
                    }
                }
            },
            //获取机器人设置状态
            getBotInfo() {
                this.settingList.id = this.$route.query.id;
                this.FetchGet(
                    this.requestUrl.bot.getBotInfo + "/" + this.settingList.id
                ).then(res => {
                    if (res.code === "0") {
                        this.isFirst = true;
                        this.isSave = true;
                        this.feedbackIsChange = false;
                        this.fastSelectIsChange = false;
                        let data = res.data;
                         // 不识别意图推荐最后一项
						data.setting.noDescernLastKeyWordRecommendIntents = data.setting.noDescernLastKeyWordRecommendIntents ? data.setting.noDescernLastKeyWordRecommendIntents : false;
                        if(data.setting.noDescernLastKeyWordRecommends){
                             data.setting.noDescernLastKeyWordRecommends = {
                                keywords:data.setting.noDescernLastKeyWordRecommends.keywords,
                                intentIds:data.setting.noDescernLastKeyWordRecommends.intentIds + ''
                            }
                        } else {
                            data.setting.noDescernLastKeyWordRecommends = {
                                keywords:'以上都不是',
                                intentIds:''
                            }
                        }
                         // 关键词推荐最后一项
                      data.setting.lastKeyWordRecommendIntents = data.setting.lastKeyWordRecommendIntents ? data.setting.lastKeyWordRecommendIntents : false;
                        if(data.setting.lastKeyWordRecommends){
                            data.setting.lastKeyWordRecommends = {
                                keywords:data.setting.lastKeyWordRecommends.keywords,
                                intentIds:data.setting.lastKeyWordRecommends.intentIds + ''
                            }
                        } else {
                           data.setting.lastKeyWordRecommends = {
                                keywords:'以上都不是',
                                intentIds:''
                            }
                        }
                       
                        this.settingList = data;
                        this.settingList.setting.sessionKeepTime = this.settingList.setting.sessionKeepTime / 60
                        if (this.settingList.setting == null) {
                            this.settingList.setting = {};
                            localStorage.setItem('showMiniEnt', 1);
                        } else {
                            if (data.setting.showAppletTag !== false) {
                                localStorage.setItem('showMiniEnt', 1);
                            } else {
                                localStorage.setItem('showMiniEnt', 0);
                            }
                        }
                        this.$nextTick(() => {
                            this.isSave = true;
                        });
                        console.log(this.settingList,'普通信息');
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            //获取机器人高级设置状态
            getBotadvancedSetting() {
                this.FetchGet(
                    this.requestUrl.bot.getBotadvancedSetting + "/" + this.$route.query.id
                ).then(res => {
                    if (res.code === "0") {
                        this.isFirst = true;
                        this.isSave = true;
                        this.feedbackIsChange = false;
                        this.fastSelectIsChange = false;
                        let data = res.data;
                        if (('feedbackSetting' in data) && data.feedbackSetting != null) {
                            this.feedbackSeetingObj = data.feedbackSetting;
                            this.feedbackSeetingObjStr = JSON.stringify(data.feedbackSetting);
                            if(data.feedbackSetting.positiveFeedback == null){
                                this.feedbackSeetingObj.positiveFeedback = {
                                    scope: "TEXT",
                                    text: this.$t('botIntent.used'),
                                    value: ""
                                }
                            }
                            if(data.feedbackSetting.negativeFeedback == null){
                                this.feedbackSeetingObj.negativeFeedback = {
                                   scope: "TEXT",
									text: this.$t('botIntent.noUse'),
									value: ""
                                }
                            }
                        } else {
                          	this.feedbackSeetingObj = {
								feedback: false,
								positiveFeedback: {
									scope: "TEXT",
									text: this.$t('botIntent.used'),
									value: ""
								},
								negativeFeedback: {
									scope: "TEXT",
									text: this.$t('botIntent.noUse'),
									value: ""
								}
							}
                            this.feedbackSeetingObjStr = JSON.stringify(this.feedbackSeetingObj);
                        }
                        let fastSelectObj = {
                            fastSelect: false,
                            group:data.group ? data.group : false,
                            list: [],
                            fastSelectSettingGroups:[{
                                list:[{
                                    text: '',
                                    orderNum:1,
                                    action: {
                                        scope: 'INTENT',
                                        value: '',
                                    },
                                }],
                                fastSelectVisibleRanges:[],
                                selfId:uuidv4().toString().replaceAll("-", "")
                            }]
                        };
                        let resetData = JSON.parse(JSON.stringify(fastSelectObj))
                        if(fastSelectObj.group){
                            fastSelectObj.fastSelect = data.fastSelectSetting.fastSelect;
                            fastSelectObj.fastSelectSettingGroups = [];
                            if(data.fastSelectSettingGroups && data.fastSelectSettingGroups.length > 0){
                                data.fastSelectSettingGroups.forEach((item,index) => {
                                    item.selfId =  uuidv4().toString().replaceAll("-", "");
                                    item.fastSelectVisibleRanges = [...item.fastSelectVisibleRanges];
                                    item.list.forEach((v,i) => {
                                        v.orderNum = i+1;
                                        v.selfId =  uuidv4().toString().replaceAll("-", "");
                                    })
                                    let list = [];
                                    item.fastSelectVisibleRanges.forEach(_v => {
                                        if(_v.type === 4){
                                            // list = [];
                                        } else {
                                            if(_v.data){
                                                list.push(_v.data)
                                            } else {
                                                list.push({
                                                   id:_v.id, 
                                                   label:_v.label, 
                                                   type:_v.type, 
                                                })
                                            }
                                           
                                        }
                                    })
                                    fastSelectObj.fastSelectSettingGroups.push({
                                        list:[...item.list],
                                        fastSelectVisibleRanges:list,
                                        selfId:uuidv4().toString().replaceAll("-", "")
                                    });
                                })
                            } else {
                                fastSelectObj = resetData;
                            }
                            
                           
                           
                        } else {
                            if (('fastSelectSetting' in data) && data.fastSelectSetting != null) {
                                fastSelectObj.fastSelect = data.fastSelectSetting.fastSelect;
                                data.fastSelectSetting.list.forEach((item,index) => {
                                   item.selfId =  uuidv4().toString().replaceAll("-", "");
                                   item.orderNum = index+1;
                                })
                                fastSelectObj.fastSelectSettingGroups = [{
                                    selfId:uuidv4().toString().replaceAll("-", ""),
                                    list:data.fastSelectSetting.list || [],
                                    fastSelectVisibleRanges:[]
                                }];
                            } else {
                                fastSelectObj = resetData;
                            }
                        }
                        this.fastSelectObjStr = JSON.stringify(fastSelectObj);
                        this.fastSelectObj = Object.assign(fastSelectObj)
                        this.fastSelectObjCopy = this.fastSelectObj;
                        let keyWordRecommends = data.keyWordRecommends || [];
                        this.keyWordRecommends = [];
						keyWordRecommends.length > 0 && keyWordRecommends.forEach((item,index) => {
							let keyWordRecommend = item || {};
                            keyWordRecommend.showBotKeywordRecommend = false;
							keyWordRecommend.keywordsStr = keyWordRecommend.keywords?keyWordRecommend.keywords.join(","):"";
                            keyWordRecommend.selectedOptions = [];
                            keyWordRecommend.isUse = false;
                            if(keyWordRecommend && keyWordRecommend.intentChildDtos && keyWordRecommend.intentChildDtos.length > 0){
                                keyWordRecommend.intentChildDtos.forEach((v,i) => {
                                    keyWordRecommend.selectedOptions.push(v.id);
                                })
                            } else {
                                keyWordRecommend.intentIds.forEach((v,i) => {
                                    keyWordRecommend.selectedOptions.push(v.id);
                                })
                                keyWordRecommend.intentChildDtos = keyWordRecommend.intents || []
                            }
                            this.keyWordRecommends.push(keyWordRecommend);
                            this.keyWordRecommendList = [...this.keyWordRecommendList,...keyWordRecommend.intentChildDtos || []]
						})
                        
                        this.settingList.setting.lastKeyWordRecommendIntents = data.lastKeyWordRecommendIntents ? data.lastKeyWordRecommendIntents : false;
                        if(data.lastKeyWordRecommends){
                             this.settingList.setting.lastKeyWordRecommends = {
                                keywords:data.lastKeyWordRecommends.keywords,
                                intentIds:data.lastKeyWordRecommends.intentIds + ''
                            }
                        } else {
                           this.settingList.setting.lastKeyWordRecommends = {
                                keywords:'以上都不是',
                                intentIds:''
                            }
                        }

                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            },
            getIntentList() {
                this.FetchGet(
                    this.requestUrl.intent.getIntentTree + "?bId=" + this.$route.query.id + "&filterType=1"
                ).then(res => {
                    if (res.code === "0") {
                        let data = res.data;
                        this.intentTree = this.handlerIntentData(data);
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            },
            handlerIntentData(data){
                if(data && data.length > 0){
                    data.forEach((item,index) => {
                        if(item.id.indexOf('S') !== -1){
                            item.dataType = 'treeIntent';
                            item.typeStr = 'SKILL';
                            this.handlerIntentData(item.children || [])
                        }else if(item.id.indexOf('T') !== -1){
                            item.dataType = 'treeIntent';
                            item.typeStr = 'TYPE';
                            this.handlerIntentData(item.children || [])
                        } else {
                            item.dataType = 'treeIntent';
                            item.typeStr = 'INTENT';
                            item.children = null;
                        }
                    })
                    return data
                }
               
            },
            getIntentListOther(){
                this.FetchGet(
                    this.requestUrl.intent.getIntentTree + "?bId=" + this.$route.query.id
                ).then(res => {
                    if (res.code === "0") {
                        let data = res.data;
                        this.intentTreeOther = data;
                        console.log(this.intentTreeOther,'intentTreeOtherintentTreeOther');
                        /* if (this.settingAdvancedList.keyWordRecommends==null){
                                      this.settingAdvancedList.keyWordRecommends = {};
                                  } */
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            },
            // 保存
            updateBot() {
               
                let flag = true;
                if(this.currentTab === 'bot-setting-keyWordRecommendIntents'){
                    if (JSON.stringify(this.keyWordRecommends) != '[{"keywords":[],"keywordsStr":"","selectedOptions":[],"intentIds":[],"intents":[]}]') {
                        this.keyWordRecommends.forEach(item => {
                            if (item.keywordsStr == "" && flag) {
                                this.$message.error(this.$t('botIntent.botSettingNew.keywordRequired'));
                                flag = false;
                            } else {
                                if (item.intentChildDtos.length == 0 && flag) {
                                    this.$message.error(this.$t('botIntent.botSettingNew.associationIntentionRequired'));
                                    flag = false;
                                }
                            }
                        });
                    }
                }
                if (flag) {
                    let settingListCopy = JSON.parse(JSON.stringify(this.settingList))
                    // this.settingList.id = this.$route.query.id;
                    settingListCopy.id = this.$route.query.id;
                    let nickName = this.settingList.setting.nickName;
                    let reply4NoCustomServer = this.settingList.setting.reply4NoCustomServer;
                    let noDescernIntentON = this.settingList.setting.noDescernIntentON;
                    let noDescernIntentLeadMessage =
                        this.settingList.setting.noDescernIntentLeadMessage || "";
                    let keyWordRecommendIntents = this.settingList.setting
                        .keyWordRecommendIntents;
                    let keyWordRecommendIntentsGuidDesc = this.settingList.setting
                        .keyWordRecommendIntentsGuidDesc;
                    if(this.currentTab === 'bot-setting-keyWordRecommendIntents'){
                        if (
                            (keyWordRecommendIntents == true &&
                                keyWordRecommendIntentsGuidDesc.trim() == null) ||
                            (keyWordRecommendIntents == true &&
                                keyWordRecommendIntentsGuidDesc.trim() == "")
                        ) {
                            this.$message.error(this.$t('botIntent.botSettingNew.keywordRecommendIntentLeadMsg'));
                            return;
                        }
                     }
                    if(this.currentTab === 'bot-setting-noDescernIntentON'){
                        if (noDescernIntentON == 1 && noDescernIntentLeadMessage.trim() == "") {
                            this.$message.error(this.$t('botIntent.botSettingNew.recommendIntentLeadMsg'));
                            return;
                        }
                    }
                    // if (reply4NoCustomServer == null || reply4NoCustomServer.trim() == "") {
                    //     this.$message.error("请填写人工客服不在线时回复!");
                    //     this.isSave = true;
                    //     return;
                    // }
                    settingListCopy.setting.feedbackSetting = this.feedbackSeetingObj;
                    // this.settingList.setting.feedbackSetting = this.feedbackSeetingObj;
                    let flags = true;
                    let quickGroup = [];
                    let fastSelectSetting = {
                        fastSelect:this.fastSelectObjCopy.fastSelect,
                        list:[]
                    };
                    if( this.fastSelectObjCopy.fastSelectSettingGroups){
                        this.fastSelectObjCopy.fastSelectSettingGroups.forEach(item => {
                        let obj = {
                            fastSelectVisibleRanges:[], // 部门
                            list:[],
                        }
                        if(item.fastSelectVisibleRanges.length > 0){
                            item.fastSelectVisibleRanges.forEach(deptItem=> {
                                obj.fastSelectVisibleRanges.push({
                                    type:deptItem.selfType,
                                    id:deptItem.id,
                                    label:deptItem.label,
                                    data:deptItem
                                })
                            })
                        } else {
                            obj.fastSelectVisibleRanges = [{
                                type:4,
                            }]
                        }
                        if(item.list.length > 0){
                            item.list.forEach(listItem=> {
                                obj.list.push({
                                    text: listItem.text,
                                    action: {
                                        scope: listItem.action.scope,
                                        value: listItem.action.value,
                                    },
                                })
                            })
                        } else {
                            obj.list = []
                        }
                        quickGroup.push(obj);
                    })
                        if(this.fastSelectObjCopy.group){
                            fastSelectSetting.list = [];
                        } else {
                            fastSelectSetting.list = quickGroup[0] ? quickGroup[0].list : [];
                            quickGroup = [];
                        }
                    } else {
                        quickGroup = [];
                        fastSelectSetting = this.fastSelectSetting;
                    }
                    this.keyWordRecommends.length > 0 && this.keyWordRecommends.forEach((item,index) => {
                        if(item.keywordsStr[item.keywordsStr.length -1] === ',' || item.keywordsStr[item.keywordsStr.length -1] === '，'){
                            item.keywordsStr = item.keywordsStr.slice(0,item.keywordsStr.length-1);
                           item.keywordsStr = item.keywordsStr.replace(/，/gi, ",");
                            item.keywords = item.keywordsStr.split(',')
                        }
                    })
                   
                    // if (nickName) {
                        if(!flags) return;
                        let sessionKeepTime = parseInt(this.settingList.setting.sessionKeepTime) * 60;
                        //  settingListCopy
                        let params = {
                            botSetting: {
                                // nickName: this.settingList.setting.nickName,
                                reply4NoCustomServer: reply4NoCustomServer,
                                noDescernIntentON: settingListCopy.setting.noDescernIntentON,
                                noDescernIntentLeadMessage: settingListCopy.setting
                                    .noDescernIntentLeadMessage,
                                keyWordRecommendIntents: settingListCopy.setting
                                    .keyWordRecommendIntents,
                                keyWordRecommendIntentsGuidDesc: keyWordRecommendIntentsGuidDesc,
                                keyWordRecommends: this.keyWordRecommends,
                                sessionKeepTime: sessionKeepTime,
                                sessionKeep: settingListCopy.setting.sessionKeep,
                                feedbackSetting: settingListCopy.setting.feedbackSetting,
                                fastSelectSetting:fastSelectSetting,
                                fastSelectSettingGroups:quickGroup,//grup分组
                                group:this.fastSelectObjCopy.group,
                                lastKeyWordRecommendIntents:settingListCopy.setting.lastKeyWordRecommendIntents,
                                lastKeyWordRecommends:settingListCopy.setting.lastKeyWordRecommends,
                                noDescernLastKeyWordRecommendIntents:settingListCopy.setting.noDescernLastKeyWordRecommendIntents,
                                noDescernLastKeyWordRecommends:settingListCopy.setting.noDescernLastKeyWordRecommends
                            }
                        }
                        console.log(params,'paramsparams');
                        this.FetchPut(this.requestUrl.bot.updateBot, this.settingList.id, params).then(res => {
                            if (res.code === "0") {
                                // this.getBotInfo();
                                this.isSave = true;
                                this.feedbackIsChange = false;
                                this.fastSelectIsChange = false;
                                this.keyWordRecommendsLength = this.keyWordRecommends.length;
                                console.log(this.useKeyWordList,'useKeyWordList');
                                if(this.useKeyWordList.length > 0){
                                    let list = [];
                                    this.useKeyWordList.forEach((item,index) => {
                                        list.push(item.id)
                                    })
                                    this.FetchPut(this.requestUrl.bot.batchAcceptConfigKeyword,'',{
                                        list:list
                                    }).then(res => {
                                        if (res.code === "0") {
                                            console.log(res);
                                            this.$message.success(this.$t('botIntent.botSettingNew.updateSuccess'));
                                        } else {
                                            this.$message.error(res.message);
                                        }
                                    }) 
                                } else {
                                    this.$message.success(this.$t('botIntent.botSettingNew.updateSuccess'));
                                }
                               
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    // } else {
                    //     this.$message.error("昵称不能为空哦！");
                    // }
                }
            }
        },
        destroyed(){
            document.querySelector('#botIntent').removeEventListener('click',() => {})
        },
    };
</script>
<style lang="less" scoped>
    @import "./../../assets/less/main/common.less";
    @import "./../../assets/less/botIndex/botSettingNew.less";

    .el-checkbox {
        min-width: 0px !important;
    }
</style>